
$body-color: rgb(20, 20, 20);
$brand-green: rgb(10, 86, 64);
$brand-yellow: rgb(255, 204, 0);

$font-family-sans-serif: "Gotham A", "Gotham B", Helvetica, Arial, sans-serif;

$primary: $brand-green;
$dark: $brand-green;

$navbar-dark-color: white;


//$link-color: $body-color;
//$card-cap-bg: $brand-yellow;
//$card-border-color: red;
@import '~bootstrap/scss/bootstrap.scss';

html, body, #root {
  min-height: 100%;
}

.site {
  display: flex;
  flex-direction: column;
}

.site-main {
  padding-bottom: 3rem;
}

.navbar-main {
  .navbar-nav {
    margin-left: auto;
    font-size: 21px;
  }
}

.footer-wrapper {
  background: $brand-green;
  color: white;
  padding: 64px 0;
  //position: sticky;
  bottom: 0;
  a {
    color: white;
  }

  ul a {
    text-decoration: none;
    padding: 0.5rem 0;
    display: inline-block;
  }

  .image {
    margin-bottom: 2rem;
  }

  .block-2 {
    position: relative;
    padding-top: 2rem;
    margin-top: 2rem;
  }

  .block-2::before {

    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    width: 32px;
    height: 1px;
    background: #fff;

  }

  .site-footer {
    border-top: 1px solid #0a3927;
    padding-top: 2rem;
    margin-top: 2rem;
  }
  //.site-footer .footer-inner::before {
  //
  //  content: '';
  //  display: block;
  //  position: absolute;
  //  top: 0;
  //  left: 50%;
  //  width: 1264px;
  //  height: 1px;
  //  -webkit-transform: translateX(-50%);
  //  transform: translateX(-50%);
  //  background: #0a3927;
  //
  //}
}

.text-right {
  text-align: right;
}

.trial-title-comparing {
  font-size: 1.5rem;
}

.table-sm {
  font-size: 0.8rem;
  th {
    &.sortable {
      cursor: pointer;
      .column-heading {
        white-space: nowrap;
      }
      .column-sort-indicator__unit {
        color: red;
      }
    }
    &.sorting {
    }
    .unsorted {
      //line-height: 0.5;
      //width: 13px;
    //  height: 14px;
    //  position: relative;
    //  top: 2px;
    //  :first-child {
    //    position: absolute;
    //    top: -3px;
    //  }
    //  :last-child {
    //    position: absolute;
    //    bottom: -3px;
    //  }
    }
  }
  .column-sort-indicator {
    display: inline-block;
    //width: 13px;
  }
}

.thead-sticky {
  box-shadow: 0 1px 0 0 currentColor;
}

.link-download {
  text-decoration: none;
}

.card-body {
  ul:last-child {
    margin-bottom: 0;
  }
}

.list-group-flush {
  .list-group-item {
    text-transform: uppercase;
    &:first-child {
      border-top-width: 1px;
    }
    &:hover {
      background: transparent;
      &:before {
        content: '';
        position: absolute;
        left: -2px;
        top: 4px;
        bottom: 4px;
        width: 4px;
        background: $brand-green;
      }
    }
    &.active {
      background: transparent;
      color: inherit;
      border-color: $list-group-border-color;
      //border-color: red;
      position: relative;
      border-top: none;
      &:before {
        content: '';
        position: absolute;
        left: -2px;
        top: 4px;
        bottom: 4px;
        width: 4px;
        background: $brand-yellow;
      }

    }
  }
}


.card-stat {
  text-align: center;
  .card-body {
    padding: 0.75rem 0.5rem;
  }
  .card-title {
    font-size: 0.8rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
    font-weight: bold;
  }
  .stat-value {
    font-size: 1.25rem;
    line-height: 1.125;
    font-weight: $font-weight-light;
  }
}


.mapwrap {
  height: 500px;
}
.map-container {
  height: 500px;
  width: 100%;
  //flex-grow: 1;
  //background: red;
}

.map-modal__close {
  z-index: 100;
}

.map-modal {
  position: absolute;
  top: 40px;
  left: 40px;
  right: 40px;
  bottom: 40px;
  background: white;
  display: flex;
  flex-direction: column;
}

.map-modal__overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(black, 0.5);
}

